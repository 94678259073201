@import "../../../variables";
@import "../../../Global.scss";
.settingSidebar {
    width: 350px;
    h1 {
        font-size: 18px;
        line-height: 27px;
        margin: 0;
    }
    .profilePhoto {
        margin-top: 16px;
        display: flex;
        align-items: center;
        .profile {
            width: 70px;
            height: 70px;
            border-radius: 50%;
            background: $primary-color;
            margin-right: 16px;  
            display: flex;          
            align-items: center;
            justify-content: center;
        }
        .btn {
            text-transform: uppercase;
            line-height: 21px;
            padding: 9px 21px;
            border-radius: 10px;
            font-weight: 500;
            &.noBg {
                border: 1px solid rgba(35, 31, 32, 0.3);
                color: rgba(35, 31, 32, 0.3);
                &:hover {
                    border-color: rgba(35, 31, 32, 1);
                    color: rgba(35, 31, 32, 1);
                }
            }
        }
    }
    .uploadPhotos {
        margin-top: 16px;
        .primaryPhoto {
            border: 1px dashed $black;
            border-radius: 20px;
            padding: 16px;
            text-align: center;
            height: 135px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;            
            p {
                font-size: 14px;
                line-height: 18px;
                color: rgba(35, 31, 32,0.3);
                margin-top: 10px;
            }
            svg {
                path {
                    fill: rgba(35, 31, 32,0.3);
                }
            }
        }
        .photoWrap {
            display: flex;
            flex-wrap: wrap;
            gap: 0 16px;
            .smallPhoto {
                padding: 16px;
                height: 140px;
                border: 1px dashed $black;
                border-radius: 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center; 
                flex: 0 0 calc(50% - 8px);
                margin-top: 16px;
                svg {
                    path {
                        fill: rgba(35, 31, 32,0.3);
                    }
                }
            }
        }
    }
}