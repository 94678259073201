@import "../../variables.module.scss";

.loginBg {
  background: #4e297b;
  height: 100vh;
  display: flex;
  justify-content: center;

  &.loginFull {
    background: url(../../Assets/images/purpleBg.jpg) no-repeat 0 0;
    background-size: cover;
  }

  .wrapContent {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 899px) {
      width: 100%;
    }

    @media (max-width: 600px) {
      width: 90%;
    }

    .formBox {
      background: #fff;
      border-radius: 30px;
      padding: 60px;
      box-shadow: 0px 2px 46px 0px #0000001a;
      text-align: center;
      max-width: 570px;
      width: 570px;
      margin: 0 auto;
      height: calc(100vh - 100px);
      position: relative;
      display: flex;

      &.formBigBox {
        overflow-y: auto;
      }

      &.formWelcome {
        flex-direction: column;
        overflow-y: auto;

        .contentBox {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;

          @media (min-width: 1200px) and (max-width: 1399px) {
            justify-content: flex-start;
          }

          .titleBox {
            margin-top: 50px;

            @media (max-width: 600px) {
              margin-top: 15px;
            }
          }
        }
      }

      @media (max-width: 1199px) {
        padding: 40px;
        max-width: 85%;
        width: 85%;
      }

      @media (max-width: 899px) {
        flex-direction: column;
      }

      @media (max-width: 600px) {
        max-width: 100%;
        width: 100%;
        height: calc(100% - 40px);
        padding: 20px;
      }

      .backBtn {
        position: absolute;
        left: 20px;
        top: 20px;
        cursor: pointer;

        img {
          @media (max-width: 600px) {
            width: 24px;
            height: 24px;
          }
        }
      }

      .logo {
        img {
          width: 173px;
        }
      }

      .titleBox {
        margin: 0;

        h1 {
          font-size: 26px;
          line-height: 31px;
          font-weight: 700;
          margin: 0;
        }

        .welcomeText {
          font-size: 18px;
          line-height: 27px;
          font-weight: 500;
          color: #414141;
          margin: 20px 0;
        }
      }

      .textContent {
        margin-bottom: 50px;

        p {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 20px;
          color: #414141;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .logoMobile {
        display: none;

        @media (max-width: 899px) {
          display: block;
          padding-top: 40px;
        }

        @media (max-width: 600px) {
          padding-top: 25px;
        }

        img {
          width: 185px;

          @media (max-width: 600px) {
            width: 150px;
          }
        }
      }

      .passwordContain {
        text-align: left;
        padding: 15px 0;

        .text {
          font-size: 18px;
          line-height: 21px;
          margin-bottom: 16px;
        }

        ul {
          list-style: none;
          padding: 0;
          margin: 0;
        }

        li {
          display: flex;
          align-items: center;
          line-height: 1;
          margin-bottom: 10px;

          span {
            margin-right: 10px;
          }
        }
      }

      .formContent {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        width: 100%;

        // @media (max-width: 899px) {
        //     height: calc(100% - 100px);
        // }
        // @media (max-width: 600px) {
        //     height: calc(100% - 70px);
        // }
        &.formBigContent {
          position: relative;

          @media (min-width: 1200px) and (max-width: 1399px) {
            justify-content: flex-start;
          }

          p.errorMsg {
            top: 100%;
          }
        }

        .titleBox {
          margin: 50px 0 0;

          @media (max-width: 600px) {
            margin-top: 10px;
          }

          &.loginTitleBox {
            margin-top: 0;
          }

          h1 {
            font-size: 26px;
            line-height: 31px;
            font-weight: 700;
            margin: 0;

            @media (max-width: 600px) {
              font-size: 20px;
              line-height: 24px;
            }

            &.textShort {
              width: 77%;
              margin: 0 auto;

              @media (max-width: 1199px) {
                width: 100%;
              }
            }
          }
        }

        .inputBox {
          width: 100%;
          position: relative;

          input {
            width: 100%;
            font-family: Poppins, sans-serif;
            padding: 18px 20px;
            border: 1px solid #231f2080;
            border-radius: 10px;
            font-size: 14px;
            line-height: 16px;
            height: auto;
            margin-bottom: 20px;

            @media (max-width: 600px) {
              padding: 15px;
              margin-bottom: 15px;
            }
          }

          .otpInput {
            display: flex;
            justify-content: center;
            gap: 30px;

            @media (max-width: 1199px) {
              gap: 10px;
            }

            input {
              padding: 5px;
              width: 50px;
              height: 60px;
              text-align: center;
              border-radius: 10px;
              border: 1px solid rgba(35, 31, 32, 0.5);
              font-size: 16px;
              line-height: 19px;

              @media (max-width: 600px) {
                width: 40px;
                height: 50px;
                font-size: 14px;
                line-height: 16px;
              }
              &:focus-visible {
                outline-color: $primary-color;
              }
            }
          }  
          .timerOtp p strong {
            color: $primary-color;
          }
          .otpText {
            span.resendActive {
              color: $primary-color !important;
              font-weight: 600;
              cursor: pointer;
            }
            span.resendDisabled {
              color: rgba(78, 41, 123,0.3);
              cursor: not-allowed !important;          
            }
          }        
          fieldset {
            border: none;
          }

          .inputWrap {
            position: relative;

            .showText {
              position: absolute;
              right: 20px;
              top: 26px;
              color: #4e297b;
              font-size: 14px;
              line-height: 16px;
              transform: translateY(-50%);
              margin: 0;
              cursor: pointer;
            }

            input {
              padding-right: 65px;
            }
          }

          .countryCode {
            position: absolute;
            left: 5px;
            top: 5px;
            background: #f5f5f5;
            border-radius: 10px;
            padding: 5px;
            display: flex;
            align-items: center;
            gap: 10px;
            height: 44px;

            @media (max-width: 600px) {
              height: 38px;
            }

            .icon {
              height: 34px;

              @media (max-width: 600px) {
                height: 28px;
              }

              img {
                @media (max-width: 600px) {
                  width: 28px;
                }
              }
            }

            .text {
              font-size: 16px;
              line-height: 19px;

              @media (max-width: 600px) {
                font-size: 14px;
                line-height: 18px;
              }
            }
          }

          .phoneNumber {
            input {
              padding-left: 100px;
            }
          }

          p.errorMsg {
            top: calc(100% - 18px);            
            @media (max-width: 600px) {
              top: calc(100% - 15px);            
            }
          }
        }

        .selectBox {
          width: 100%;
          margin-bottom: 20px;
          position: relative;

          .css-w76bbz-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-w76bbz-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-w76bbz-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
            border: 1px solid rgba(35, 31, 32, 0.5019607843);
            border-radius: 10px;
            text-align: left;
          }

          .MuiSelect-select {
            height: 100px;
          }
        }

        .wrapInput {
          display: flex;
          gap: 20px;

          @media (max-width: 600px) {
            gap: 0;
            flex-wrap: wrap;
          }

          .inputBox {
            width: 50%;

            @media (max-width: 600px) {
              width: 100%;
            }
          }
        }

        .divider {
          position: relative;
          margin: 20px 0;
          line-height: 1;
          width: 100%;

          @media (max-width: 600px) {
            margin: 15px 0;
          }

          &:before {
            content: "";
            width: 100%;
            height: 1px;
            background: #231f20;
            position: absolute;
            top: 50%;
            left: 0;
          }

          span {
            font-size: 14px;
            font-weight: 400;
            background: #fff;
            padding: 0 15px;
            display: inline-block;
            position: relative;
            z-index: 1;
          }
        }

        .btnWrap {
          .btnSocial {
            width: 100%;
            border-radius: 10px;
            color: #231f20;
            border-color: #231f20;
            padding: 14px;
            text-align: center;
            font-size: 14px;
            line-height: 21px;
            font-weight: 400;
            height: auto;
            letter-spacing: 0;
            font-family: Poppins, sans-serif;
            text-transform: capitalize;

            &:first-child {
              margin-bottom: 20px;
            }

            @media (max-width: 600px) {
              padding: 11px 14px;
            }
          }

          span {
            margin-right: 10px;

            img {
              width: 24px;
              height: 24px;
              display: inline-block;
              vertical-align: middle;
            }
          }
        }

        .wrapBtns {
          display: flex;
          width: 100%;
          gap: 20px;

          .btn {
            width: 50%;

            &.btnBorder {
              background: transparent;
              color: #4e297b;
              border: 1px solid #4e297b;
            }
          }
        }

        p {
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          color: #414141;
          margin: 20px 0;

          a {
            text-decoration: none;
            color: $primary-color;
          }

          &.errorMsg {
            position: absolute;
            left: 0;
            top: calc(100% - 20px);
            color: $error;
            font-size: 12px;
            line-height: 14px;
            margin: 0;
          }

          @media (max-width: 600px) {
            font-size: 14px;
            line-height: 16px;
            margin: 10px 0;
          }
        }

        &.searchAddress {
          display: flex;

          .searchTop {
            width: 100%;

            // margin-top: 100px;
            .searchInput {
              position: relative;

              input {
                padding-right: 45px;
              }

              .searchIcon {
                position: absolute;
                right: 20px;
                top: 14px;
                cursor: pointer;

                @media (max-width: 600px) {
                  top: 12px;
                  width: 20px;
                }
              }
            }
          }

          .btnWrap {
            margin-top: auto;
            display: flex;
            align-items: center;
            width: 100%;
            gap: 30px;

            @media (max-width: 1199px) {
              flex-wrap: wrap;
              gap: 20px;
            }

            .btn {
              width: 50%;

              @media (max-width: 1199px) {
                width: 100%;
              }
            }

            a {
              color: #231f20;
              text-decoration: none;
              font-size: 16px;
              line-height: 19px;
              width: 50%;

              @media (max-width: 1199px) {
                text-align: center;
                width: 100%;
              }
            }
          }
        }

        .locationBox {
          margin-bottom: 20px;
          height: 350px;
          width: 100%;
          overflow: hidden;
          @media (max-width: 600px) {
            height: 250px;
          }

          img {
            border-radius: 10px;
            max-width: 100%;
          }
        }

        .radioWrap {
          margin: 50px 0;
          width: 100%;
          position: relative;

          .customRadio {
            color: #231f20;
            font-family: Poppins, sans-serif;
          }

          .checked {
            color: #4e297b !important; // Override the checked color
          }
          // .textarea {
          //   margin-bottom: 20px;
          // }
          p {
            margin-bottom: 0;
            p.errorMsg {
              top: calc(100% - 18px);
            }
          }
          p.errorMsg {
            top: 100%;
          }
          

          .textareaWrap {
            margin-bottom: 10px;

            textarea {
              border-radius: 10px;
              height: 50px !important;
              font-size: 14px;
              line-height: 16px;              
            }

            fieldset {
              border: none;
            }
          }
        }

        .servicesWrap {
          margin: 30px 0;
          width: 100%;
          height: 250px;
          overflow-y: auto;
          &::-webkit-scrollbar
           {
            width: 10px;
          }
          &::-webkit-scrollbar-track
          {
            background: #fff;
            border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb {
            background: #4e297b;
            border-radius: 10px;
          }

          .formSmallBox::-webkit-scrollbar-thumb:hover,
          .formBigBox::-webkit-scrollbar-thumb:hover,
          .formWelcome::-webkit-scrollbar-thumb:hover {
            background: #4e297b;
          }
          li {
            padding: 0;
            span {
              padding-left: 0;
            }
          }
          .serviceList {
            display: flex;
            // justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;
            padding-bottom: 16px;
            border-bottom: 1px solid rgba(35, 31, 32, 0.3);

            &:last-child {
              margin-bottom: 0;
            }
            .deleteIcon {
              margin-right: 10px;
              width: 25px;
            }

            .serviceName {
              display: flex;
              align-items: center;
              text-align: left;
              width: 43%;

              .deleteIcon {
                margin-right: 15px;

                @media (max-width: 600px) {
                  margin-right: 10px;
                }

                img {
                  vertical-align: middle;

                  @media (max-width: 600px) {
                    width: 16px;
                  }
                }
              }

              span {
                font-size: 18px;
                line-height: 27px;
                color: #414141;
                font-weight: 500;

                @media (max-width: 600px) {
                  font-size: 16px;
                  line-height: 24px;
                }
              }
            }

            .time {
              font-size: 14px;
              line-height: 21px;
              color: #414141;
              width: 33.33%;

              @media (max-width: 600px) {
                font-size: 12px;
                line-height: 18px;
              }
            }

            .price {
              font-size: 18px;
              line-height: 27px;
              color: #414141;
              font-weight: 500;
              display: flex;
              align-items: center;

              @media (max-width: 600px) {
                font-size: 16px;
                line-height: 24px;
              }

              .hoursArrow {
                margin-left: 15px;
                cursor: pointer;

                @media (max-width: 600px) {
                  margin-left: 10px;
                }

                img {
                  vertical-align: middle;

                  @media (max-width: 600px) {
                    width: 10px;
                  }
                }
              }
            }
          }
        }

        .addService {
          font-size: 16px;
          line-height: 24px;
          color: #231f20;
          margin-right: auto;
          margin-bottom: 30px;
          font-weight: 500;
          cursor: pointer;
        }

        .hoursWrap {
          margin-bottom: 30px;
          width: 100%;

          .hoursBox {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 20px;

            @media (max-width: 600px) {
              margin-bottom: 10px;
            }

            &:last-child {
              margin-bottom: 0;
            }

            .day,
            .time {
              font-size: 16px;
              line-height: 24px;

              @media (max-width: 600px) {
                font-size: 14px;
                line-height: 20px;
              }
            }

            .days {
              gap: 10px;
              width: 33.33%;
              text-align: left;
            }

            .arrowBox {
              cursor: pointer;              

              img {
                @media (max-width: 600px) {
                  width: 8px;
                }
              }
            }
          }
        }

        .linkText {
          display: flex;
          text-decoration: none;
          margin-bottom: 20px;
          font-size: 14px;
          line-height: 16px;
          color: $primary-color;

          &:hover {
            color: $primary-hover-color;
          }
        }

        form {
          width: 100%;
        }
        &.mapContent {
          @media (max-width: 1300px) {
            .titleBox {
              margin-top: 10px;
            }
            p {
              margin: 10px 0;
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
        &.addServiceContent {
          .servicesWrap {
            .time {
              width: 20%;
              margin: 0 10px;
              text-align: left;
            }
            .serviceList {
              .listCheckbox {
                padding-left: 0;
              }
            }
          }
          @media (max-width: 1366px) {
            .titleBox {
              margin-top: 10px;
            }
            p {
              margin: 10px 0;
              font-size: 14px;
              line-height: 20px;
            }
            .servicesWrap {
              margin: 5px 0;
              li {
                padding: 0;
                span {
                  padding-left: 0;
                }
              }
              .serviceList {
                margin-bottom: 10px;
                padding-bottom: 5px;
                .deleteIcon {
                  margin-right: 10px;
                  img {
                    width: 15px;
                  }
                }
                .serviceName {
                  width: 50%;
                  span {
                    font-size: 14px;
                    line-height: 20px;
                    text-align: left;
                  }
                }
                .price {
                  span {
                    font-size: 14px;
                    line-height: 20px;
                  }
                }
                .hoursArrow {
                  margin-left: 5px;
                  img {
                    width: 10px;
                  }
                }
              }
            }
            .addService {
              margin-bottom: 20px;
            }
          }
        }  
        .addressForm {
          overflow-y: auto;
          padding-right: 5px;
          &::-webkit-scrollbar
           {
            width: 10px;            
          }
          &::-webkit-scrollbar-track
          {
            background: #fff;
            border-radius: 10px;                        
          }

          &::-webkit-scrollbar-thumb {
            background: #4e297b;
            border-radius: 10px;            
          }
          .wrapBtns {
            position: sticky;
            bottom: 0;
            padding-top: 10px;
            background: #fff;
          }
        }      
      }

      &.formSmallBox {
        padding: 20px 35px;
        width: 570px;
        max-width: 570px;
        overflow-y: auto;
        height: calc(100vh - 100px);

        @media (max-width: 1199px) {
          width: 85%;
          max-width: 85%;
          height: calc(100% - 100px);
        }

        @media (max-width: 600px) {
          height: calc(100% - 40px);
          padding: 20px;
          width: 100%;
          max-width: 100%;
        }
        .businessWrap {
          overflow-y: auto;
          &::-webkit-scrollbar
           {
            width: 10px;
          }
          &::-webkit-scrollbar-track
          {
            background: #fff;
            border-radius: 10px;
          }

          &::-webkit-scrollbar-thumb {
            background: #4e297b;
            border-radius: 10px;
          }
        }
        .serviceWrap {
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          width: 100%;          

          .serviceBox {
            width: 33.33%;
            text-align: center;
            margin-bottom: 30px;
            cursor: pointer;

            @media (max-width: 1199px) {
              margin-bottom: 15px;
            }
            @media (max-width: 600px) {
              margin-bottom: 10px;
            }

            .serviceIcon {
              width: 70px;
              height: 70px;
              border: 1px solid #fab93b;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 0 auto;

              @media (max-width: 600px) {
                width: 50px;
                height: 50px;
              }

              img {
                @media (max-width: 600px) {
                  width: 40px;
                }
              }
            }

            .serviceText {
              font-size: 14px;
              line-height: 16px;
              font-weight: 600;
              margin-top: 10px;

              @media (max-width: 600px) {
                font-size: 12px;
                line-height: 14px;
                margin-top: 5px;
              }
            }
            &.selected {
              .serviceIcon {
                background: $primary-color;
                img {
                  filter: brightness(0) invert(1);
                }
              }
            }
          }
        }

        .otherCategories {
          margin-bottom: 24px;

          .title {
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            margin-bottom: 16px;
            color: #4e297b;

            @media (max-width: 600px) {
              margin-bottom: 10px;
            }
          }

          .categoryWrap {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 10px;
          }

          .categoryLabel {
            background: #f5f5f5;
            color: #231f20;
            font-size: 13px;
            line-height: 9px;
            padding: 12px;
            border-radius: 30px;
            margin-bottom: 6px;
            cursor: pointer;

            @media (max-width: 1199px) {
              margin-bottom: 0;
            }

            @media (max-width: 600px) {
              font-size: 12px;
              padding: 6px;
            }
            &.selected {
              background: $primary-color;
              color: #fff;
            }
          }

          @media (max-width: 1199px) {
            margin-bottom: 15px;
          }

          @media (max-width: 899px) {
            margin-bottom: 20px;
          }
        }

        .labelWrap {
          display: flex;
          flex-wrap: wrap;
          gap: 10px;
          margin-bottom: 30px;
          position: relative;
        }

        .label {
          background: #f5f5f5;
          color: #231f20;
          font-size: 13px;
          line-height: 9px;
          padding: 12px;
          border-radius: 30px;
          margin-bottom: 6px;
          cursor: pointer;

          &.selected {
            background: $primary-color;
            color: #fff;
          }

          @media (max-width: 1199px) {
            margin-bottom: 0;
          }

          @media (max-width: 600px) {
            font-size: 12px;
            padding: 6px;
          }
        }
      }

      .btn {
        background: #4e297b;
        color: #fff;
        width: 100%;
        border-radius: 10px;
        font-size: 18px;
        line-height: 21px;
        font-weight: 400;
        padding: 20px;
        text-transform: capitalize;
        font-family: Poppins, sans-serif;
        box-shadow: none;
        border: none;
        position: relative;
        .loaderSpinner {
          position: absolute;
          left: calc(50% + 50px);
          transform: translateX(-50%);
        }

        @media (max-width: 600px) {
          font-size: 15px;
          line-height: 18px;
          padding: 15px;
          margin-bottom: 10px;
        }
      }

      .checkBoxWrap {
        margin-bottom: 20px;
        padding-left: 10px;
        position: relative;

        .checkBoxField {
          margin-bottom: 20px;
          position: relative;

          &:last-child, &:nth-child(2) {
            margin-bottom: 0;
          }

          label {
            p {
              font-size: 13px;
              line-height: 19px;
              margin: 0;
              margin-left: 6px;
              text-align: left;
              font-family: Poppins, sans-serif;
              color: #231f20;

              @media (max-width: 600px) {
                font-size: 12px;
                line-height: 16px;
              }
            }

            .textTitle {
              text-align: left;
              font-family: Poppins, sans-serif;
              color: #231f20;
              font-size: 15px;
              line-height: 22px;
              font-weight: 600;
              margin-left: 6px;

              @media (max-width: 600px) {
                font-size: 14px;
                line-height: 18px;
              }
            }
          }

          .customCheckbox {
            fill: #4e297b;
            border: 1px solid #231f20;
            border-radius: 8px;
            color: #fff;
            height: 30px;
            padding: 0;
            position: relative;
            min-width: 30px;
            margin-right: 9px;

            @media (max-width: 600px) {
              min-width: 24px;
              height: 24px;
              border-radius: 6px;
              margin-right: 5px;
            }

            input,
            svg {
              width: 100%;
              height: 100%;
            }
          }

          .checked {
            &::before {
              background: #4e297b;
              border-radius: 10px;
              content: "";
              height: 30px;
              left: -1px;
              position: absolute;
              top: -1px;
              width: 30px;

              @media (max-width: 600px) {
                width: 24px;
                height: 24px;
                border-radius: 6px;
              }
            }

            &::after {
              content: "";
              background: url('data:image/svg+xml,<svg viewBox="-0.5 -0.5 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" id="Check--Streamline-Outlined-Material" height="24" width="24"><desc>Check Streamline Icon: https://streamlinehq.com</desc><g id="check"><path id="Vector" d="m9.056345833333335 17.106345833333336 -5.366666666666666 -5.366666666666666 1.0302083333333334 -1.0302083333333334 4.336458333333334 4.336458333333334 9.2 -9.2 1.0302083333333334 1.0302083333333334 -10.230208333333334 10.230208333333334Z" fill="%23ffffff" stroke-width="1"></path></g></svg>');
              width: 20px;
              height: 20px;
              position: absolute;
              left: 2px;
              top: 2px;
              z-index: 1;

              @media (max-width: 600px) {
                left: 0;
                top: 0;
              }
            }
          }

          p.errorMsg {
            left: -10px;            
          }
        }
        p.errorMsg {
          top: 100%;
        }
      }
      form {
        width: 100%;
      }
    }
  }

  .companyBg {
    background: url(../../Assets/images/companyBg.jpg) no-repeat 0 0;
    align-items: center;
    color: #fff;
    display: flex;
    justify-content: center;
    min-height: auto;
    position: relative;
    background-size: cover;

    img {
      @media (max-width: 1199px) {
        width: 300px;
      }
    }

    @media (max-width: 899px) {
      display: none;
    }
  }
}

/* === Scroll CSS ======= */

.formSmallBox::-webkit-scrollbar,
.formBigBox::-webkit-scrollbar,
.formWelcome::-webkit-scrollbar {
  width: 10px;
}

.formSmallBox::-webkit-scrollbar-track,
.formBigBox::-webkit-scrollbar-track,
.formWelcome::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
}

.formSmallBox::-webkit-scrollbar-thumb,
.formBigBox::-webkit-scrollbar-thumb,
.formWelcome::-webkit-scrollbar-thumb {
  background: #4e297b;
  border-radius: 10px;
}

.formSmallBox::-webkit-scrollbar-thumb:hover,
.formBigBox::-webkit-scrollbar-thumb:hover,
.formWelcome::-webkit-scrollbar-thumb:hover {
  background: #4e297b;
}

/* === Popup CSS ======= */
.popupContent {
  background: #fff;
  height: auto;
  max-width: 530px;
  border-radius: 20px;
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  padding: 30px 40px;
  outline: none;

  &.smallContent {
    width: 530px;

    @media (max-width: 600px) {
      width: 90%;
    }

    .selectBox {
      margin-bottom: 30px;
      position: relative;
    }

    .inputBox {
      input {
        @media (max-width: 600px) {
          margin-bottom: 30px !important;
        }
      }
    }
  }  
  @media (max-width: 600px) {
    width: 90%;
    padding: 20px;
  }

  .closeIcon {
    text-align: right;
    cursor: pointer;
  }

  .titleBox {
    text-align: center;
    .titleWrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      @media (max-width: 1300px) {
        margin-bottom: 10px;
      }
      .title {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0;
      }
    }
    .serviceText {
      @media (max-width: 1300px) {
        font-size: 13px;
        line-height: 20px;
      }
    }
    .title {
      font-size: 26px;
      line-height: 31px;
      font-weight: 700;
      margin-bottom: 20px;

      @media (max-width: 600px) {
        font-size: 20px;
        line-height: 24px;
      }
    }

    p {
      font-size: 16px;
      line-height: 24px;
      color: #414141;

      @media (max-width: 600px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .contentBox {
    margin: 30px 0;
    &.serviceDetail {
      @media (max-width: 1300px) {
        margin: 10px 0;
      }
    }

    @media (max-width: 600px) {
      margin: 20px 0;
    }

    .hoursBox {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 30px;

      @media (max-width: 600px) {
        margin-bottom: 20px;
      }

      .text {
        color: #414141;
        font-size: 16px;
        line-height: 24px;

        @media (max-width: 600px) {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 10px;
          width: 100%;
        }
      }

      .selectBox {
        gap: 20px;
        display: flex;
        position: relative;
      }

      @media (max-width: 600px) {
        flex-wrap: wrap;
      }
    }

    .slotBox {
      display: flex;
      flex-wrap: wrap;

      .slotItem {
        display: flex;
        gap: 10px;
        margin-bottom: 10px;
        margin-left: auto;
      }
    }

    .addSlots {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      color: #231f20;
      margin-bottom: 30px;

      @media (max-width: 600px) {
        margin-bottom: 20px;
      }
    }

    .wrapBtns {
      display: flex;
      width: 100%;
      gap: 20px;

      .btn {
        width: 50%;
        padding: 20px;
        box-shadow: none;
        border-radius: 10px;
        font-size: 16px;
        line-height: 19px;

        @media (max-width: 600px) {
          font-size: 15px;
          line-height: 18px;
          padding: 15px;
          margin-bottom: 10px;
        }

        &.btnBorder {
          background: transparent;
          color: #4e297b;
          border: 1px solid #4e297b;
        }
      }
    }

    .inputBox {
      width: 100%;
      position: relative;

      input {
        width: 100%;
        font-family: Poppins, sans-serif;
        padding: 18px 20px;
        border: 1px solid #231f2080;
        border-radius: 10px;
        font-size: 14px;
        line-height: 16px;
        height: auto;
        margin-bottom: 30px;

        @media (max-width: 600px) {
          padding: 15px;
          margin-bottom: 15px;
        }
      }

      fieldset {
        border: none;
      }

      .inputWrap {
        position: relative;

        .showText {
          position: absolute;
          right: 20px;
          top: 26px;
          color: #4e297b;
          font-size: 14px;
          line-height: 16px;
          transform: translateY(-50%);
          margin: 0;
          cursor: pointer;
        }

        input {
          padding-right: 65px;
        }
      }
    }

    .checkBoxField {
      margin-bottom: 20px;
      width: 50%;
      padding-left: 12px;

      &:last-child {
        margin-bottom: 0;
      }

      label {
        p {
          font-size: 13px;
          line-height: 19px;
          margin: 0;
          margin-left: 6px;
          text-align: left;
          font-family: Poppins, sans-serif;
          color: #231f20;

          @media (max-width: 600px) {
            font-size: 12px;
            line-height: 16px;
          }
        }

        .textTitle {
          text-align: left;
          font-family: Poppins, sans-serif;
          color: #231f20;
          font-size: 15px;
          line-height: 22px;
          font-weight: 600;
          margin-left: 6px;

          @media (max-width: 600px) {
            font-size: 14px;
            line-height: 18px;
          }
        }
      }

      .customCheckbox {
        fill: #4e297b;
        border: 1px solid #231f20;
        border-radius: 8px;
        color: #fff;
        height: 30px;
        padding: 0;
        position: relative;
        min-width: 30px;
        margin-right: 9px;

        @media (max-width: 600px) {
          min-width: 24px;
          height: 24px;
          border-radius: 6px;
          margin-right: 5px;
        }

        input,
        svg {
          width: 100%;
          height: 100%;
        }
      }

      .checked {
        &::before {
          background: #4e297b;
          border-radius: 10px;
          content: "";
          height: 30px;
          left: -1px;
          position: absolute;
          top: -1px;
          width: 30px;

          @media (max-width: 600px) {
            width: 24px;
            height: 24px;
            border-radius: 6px;
          }
        }

        &::after {
          content: "";
          background: url('data:image/svg+xml,<svg viewBox="-0.5 -0.5 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" id="Check--Streamline-Outlined-Material" height="24" width="24"><desc>Check Streamline Icon: https://streamlinehq.com</desc><g id="check"><path id="Vector" d="m9.056345833333335 17.106345833333336 -5.366666666666666 -5.366666666666666 1.0302083333333334 -1.0302083333333334 4.336458333333334 4.336458333333334 9.2 -9.2 1.0302083333333334 1.0302083333333334 -10.230208333333334 10.230208333333334Z" fill="%23ffffff" stroke-width="1"></path></g></svg>');
          width: 20px;
          height: 20px;
          position: absolute;
          left: 2px;
          top: 2px;
          z-index: 1;

          @media (max-width: 600px) {
            left: 0;
            top: 0;
          }
        }
      }
    }
    .inputService, .selectBox, .inputPrice {
      .errorMsg {
        top: calc(100% - 30px);
      }
    }
    .hoursBox {
     .selectBox {
      .errorMsg {
        top: 100%;
        &.minuteError {
          left: calc(100% - 48%);
        }
      }
    }
  }
    .btn {
      width: 100%;
      padding: 15px;
      box-shadow: none;
      border-radius: 10px;
      font-size: 16px;
      line-height: 19px;
    }
  }

  .errorMsg {
    position: absolute;
    left: 0;
    top: 100%;
    color: $error;
    font-size: 12px;
    line-height: 14px;
    margin: 0;
  }
}


