.progressBar {
    width: 370px;
    height: 10px;
    border-radius: 10px;
    background: #eee;   
    position: absolute;
    overflow: hidden;
    top: 35px;
    left: 50%;
    transform: translateX(-50%);
    @media (max-width: 1199px) {
        width: 270px;
    }
    @media (max-width: 899px) {
        width: 340px;
    }
    @media (max-width: 600px) {
        width: calc(100% - 120px);
        top: 28px;
        height: 7px;
        border-radius: 7px;
    }
    
    .progress {
        position: absolute;
        left: 0;
        width: 10%;
        height: 100%;
        background: #4E297B;
    }
}