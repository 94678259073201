$primary-color: #4E297B; 
$black: #231F20;
$primary-font: "Poppins", serif;
body {
  font-family: $primary-font;
  color: $black;
  margin: 0;
  padding: 0;
}

