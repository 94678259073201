@import "../../../variables.module.scss";
.plan_card {
    min-height: 100%;
    box-sizing: border-box;
    padding: 0px;
    background-color: #f5f5f5;
    box-shadow: none;  
    width: 100%;
    border-radius: 6px;
    padding: 20px;
    border: 1px solid transparent;
    transition: all 0.3s ease-in-out;
    &:hover {
      background: #fff;
      border: 1px solid $primary-color;
    }
    @media (max-width: 899px) {
      flex: 0 0 calc(50% - 10px);
    }  
    @media (max-width: 600px) {
      flex: 0 0 100%;
    }   
  }
  
  .planType {    
    border-radius: 10px;
    padding: 7px 14px;
    font-size: 12px;
    line-height: 18px;
    font-weight: 700;  
    background: #4e297b;
    color: #fff;  
    text-transform: uppercase;
    display: inline-block;
  }
  .planCardWrapper {
    padding: 20px !important;
    border-radius: 10px;
  }
  
  #package {
    font-size: 14px;
    line-height: 17px;
    color: #636363;
  }
  .plan_amount {
    margin: 15px 0 5px;   
    font-size: 27px;
    line-height: 36px;
    font-weight: 800;
    color: #333333;    
  }
  
  .plan_title {
    margin: 15px 0 20px;  
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
  }
  
  .plan_amount .planBy {  
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #636363;
    margin-left: 5px;
  }
  
   .plan_amount .package {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: #636363;
  }
  
   .addPlanButton {
    color: #ffffff;
    font-family: $primary-font;
    width: 100%;  
    background: #4e297b;
    border: 1px solid #4e297b;
    border-radius: 6px;  
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  
  .addPlanButton:hover{
    background-color: #2e144e;
  }
  
  .setupFeeBtn {
    padding: 6px 10px;
    border-radius: 6px;
    margin-top: 16px;
    background: rgba(78, 41, 123, 0.1);  
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #4e297b;
    text-transform: capitalize;
    border: none;
  }
  
   .setupFeeBtn span {
    font-weight: 600;    
    font-size: 14px;
    line-height: 16px;
  }
  .plan_description_container { 
    margin-top: 20px;
    margin-bottom: 0;
    padding: 0;
    padding-left: 20px;
    li {
      list-style-type: disc;
      font-size: 10px;
      color: #333;
    }
  }
  
 
  .plan_description {  
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    color: #333333;
  }
 
  
  button.priceSubmit{
    background-color: #4e297b;
  }
  button.priceSubmit:hover{
    background-color: #2e144e;
  }
  button.priceCancel{
    color: #4e297b;
  }
  
    