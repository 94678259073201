@import "../../../variables";
@import "../../../Global.scss";
.settingWrap {
    display: flex;  
    .mainContent {
        margin-left: 16px;
        padding-left: 16px;
        border-left: 1px solid rgba(35, 31, 32, 0.3); 
        width: calc(100% - 350px); 
        .tabContent {
            .mediaWrap {
                display: flex;
                flex-wrap: wrap;
                gap: 0 16px;
                .btn {
                    flex: 0 0 calc(50% - 8px);
                    border-radius: 5px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 6px 16px;
                    margin-bottom: 16px;
                    svg {
                        margin-right: 16px;
                        width: 16px;                        
                    }
                    &.dashed {
                        border-color: rgba(35, 31, 32, 0.3); 
                        color: rgba(35, 31, 32, 0.3);
                        padding: 16px;
                    }
                }
            }
            .btnBox {
                width: 350px;
                margin: 20px auto 0;
                .btn {
                   border-radius: 10px; 
                   font-weight: 400;
                   width: 100%;
                   padding: 17px 15px;
                }
            }
            .formWrap {                
                display: flex;
                flex-wrap: wrap;
                margin: 16px 0;
                gap: 16px;
                .formBox {
                    width: 50%;
                    flex: 0 0 calc(50% - 8px);  
                    position: relative;     
                    @media (max-width: 600px) {
                        width: 100%;
                        flex: 0 0 100%;
                    }     
                    .countryCode {
                        align-items: center;
                        background: #f5f5f5;
                        border-radius: 10px;
                        display: flex;
                        gap: 6px;
                        height: 40px;
                        left: 5px;
                        padding: 5px;
                        position: absolute;
                        top: 5px;
                        .icon {
                            line-height: 1;
                            img {
                                width: 30px;
                                height: 30px;
                                vertical-align: middle;
                            }
                        }
                        .text {
                           font-size: 14px;
                           line-height: 16px; 
                        }
                    }
                    &.socialBox {
                  svg {
                    position: absolute;
                    left: 7px;
                    top: 7px;
                  }
                }
                }
            }
        }   
    }
}