.sidebar {
    width: 260px;
    box-shadow: 0px 4px 4px 0px #00000040;
    background: #fff;
    padding: 25px 18px;
    height: 100vh; 
    position: fixed;
    z-index: 10;
    transition: all 0.3s ease-in-out;
    @media (max-width: 899px) {
        left: -260px;
    }
    .logo {
        text-align: left;
        img {
            width: 160px;
            cursor: pointer;
        }
    }    
    ul.navbar {
        list-style: none;
        margin: 0;
        padding: 25px 0 0;
        display: flex;
        flex-direction: column;
        gap: 20px;
        li {
            padding: 0;
            a {
                color: #231F20;
                font-size: 14px;
                line-height: 22px;
                padding: 9px 18px;
                border-radius: 30px;
                text-decoration: none;
                display: flex;               
                align-items: center;
                width: 100%;
                transition: all 0.3s ease-in-out;
                &:hover {
                    background: #4E297B;                    
                    color: #fff;
                    .icon {
                        svg {
                            path {
                                fill: #fff;
                                stroke: #fff;
                            }
                        }
                    }
                }
                .icon {
                    margin-right: 15px;  
                    svg {
                        display: inline-block; 
                        vertical-align: middle; 
                        width: 20px;
                        height: 20px;
                        path {
                            transition: all 0.3s ease-in-out;              
                        }                                         
                    }                   
                }
            }
            &.active {
                a {
                    background: #4E297B;                    
                    color: #fff;
                    .icon {
                        margin-right: 15px;                   
                        svg {
                            path {
                                fill: #fff;
                                stroke: #fff;
                            }
                        }                   
                    }
                }
            }
        }
    }   
    // &.closed {
    //     width: 70px;
    // } 
}