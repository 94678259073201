@import "./variables";
.btn {
    background: $primary-color;
    color: #fff;
    font-family: "Poppins", serif;
    font-size: 14px;
    line-height: 16px;
    border: none;
    padding: 8px;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
        background: $primary-hover-color;
    }
    &.noBg {
        background: transparent;
        border: 1px solid $black;
        color: $black;       
    }
    &.withIcon {
        display: flex;
        align-items: center;
        span {
            margin-right: 10px;
        }
    }
    &.border {
        background: transparent;
        border: 1px solid $primary-color;
        color: $primary-color;
        &:hover {
            background: $primary-color;
            color: #fff;
        }
    }
    &.onlyText {
        background: transparent;
        color: #4E297B;
        border: none;
        padding: 0;        
    }
    &.dashed {
        border-style: dashed;
    }
}

.table {
    box-shadow: none !important;    
    padding: 16px;
    border-radius: 20px;
    margin-top: 20px;
    thead {
        th {
            font-size: 14px;
            line-height: 21px;
            font-weight: 600;
            padding-top: 0;
            white-space: nowrap;
            &:first-child {
                padding-left: 0;
            }
        }
    }
    tbody {
        tr {
            white-space: nowrap;
            td {  
                &:first-child {
                    padding-left: 0;
                }               
            }
            &:last-child {
                td {                        
                   border-bottom: none;
                }
            }
        }
    }
}

.popupContent {
    background: #fff;
    height: auto;    
    border-radius: 20px;
    left: 50%;
    top: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    padding: 30px;
    outline: none;
    @media (max-width: 600px) {
        width: 90%;
        padding: 20px;
    }
    .closeIcon {
        text-align: right;
        cursor: pointer;

    }
    .titleBox {
        text-align: center;
        .title {
            font-size: 26px;
            line-height: 31px;
            font-weight: 700;
            margin-bottom: 20px;
            @media (max-width: 600px) {
                font-size: 20px;
                line-height: 24px;
            }
        }
        p {
           font-size: 16px;
           line-height: 24px; 
           color: #414141;
           @media (max-width: 600px) {
            font-size: 14px;
            line-height: 20px;
          }
        }
    }
    .contentBox {
        margin: 30px 0;
        @media (max-width: 600px) {
            margin: 20px 0;
        }  
        .wrapBtns {
            display: flex;            
            width: 100%;
            gap: 20px;
            .btn {
                width: 50%;
                padding: 15px 20px;
                box-shadow: none;
                border-radius: 10px;
                font-size: 16px;
                line-height: 19px;
                @media (max-width: 600px) {
                    font-size: 15px;
                    line-height: 18px;
                    padding: 15px;
                    margin-bottom: 10px;
                    }                
                &.btnBorder {
                    background: transparent;
                    color: #4E297B;
                    border: 1px solid #4E297B;
                }                
            }
        }
        .inputBox {      
            width: 100%;   
            position: relative;   
            input {
                width: 100%;
                font-family: $primary-font;
                padding: 18px 20px;
                border: 1px solid #231F2080;
                border-radius: 10px;
                font-size: 14px;
                line-height: 16px;
                height: auto;
                margin-bottom: 30px;
                @media (max-width: 600px) {
                    padding: 15px;                    
                    margin-bottom: 15px;
                }
            }
            fieldset {
               border: none;                
            }          
        }        
    } 
}
