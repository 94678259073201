.wrap {
    display: flex;
    flex-wrap: wrap;
    .contentWrap {
        width: calc(100% - 260px); 
        margin-left: 260px; 
        transition: all 0.3s ease-in-out;   
        @media (max-width: 899px) {
            width: 100%;
            margin-left: 0;
        }   
        .wrapBox {
            padding: 0 30px 30px 30px; 
            @media (max-width: 600px) {
                padding: 0 20px 20px 20px;
            }                      
            .mainContent {
                border: 1px solid #231F20;
                border-radius: 20px; 
                padding: 16px;
            }
        }
        // &.open  {
        //     width: calc(100% - 260px); 
        // }
        // &.closed  {
        //     width: calc(100% - 70px); 
        //     margin-left: 70px;
        // }
    }
}
.btn {
    background: #4E297B;
    color: #fff;
    font-family: "Poppins", serif;
    font-size: 14px;
    line-height: 16px;
    border: none;
    padding: 8px;
    border-radius: 30px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    &:hover {
        background: #2e144e;
    }
}
