.header {
    padding: 32px 30px;    
    position: sticky;
    top: 0;
    background: #fff;
    z-index: 1;
    @media (max-width: 600px) {
        padding: 20px;
    }
    .headerWrap {
        display: flex;
        align-items: center; 
        justify-content: space-between;  
        .leftHeader {
            display: flex;
            align-items: center;
            gap: 20px;  
            .menuIcon {
                line-height: 1;
                cursor: pointer;
            }          
        .searchBox {
            position: relative;
            @media (max-width: 600px) {
                display: none;
            }
            img {
                position: absolute;
                top: 20px;
                left: 20px;
                width: 20px;
            }
        } 
    }
        .profileBox {
            display: flex;
            align-items: center;
            gap: 20px;
            .icon {
                cursor: pointer;
                img {
                    width: 26px;
                    height: 26px;
                    @media (max-width: 600px) {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
            .profileWrap {
                display: flex;
                align-items: center;
                gap: 16px;
                background: #4E297B;
                border-radius: 40px;
                padding: 5px 5px 5px 16px;
                @media (max-width: 600px) {
                    padding: 0;
                }
                cursor: pointer;
                .name {
                    color: #fff;
                    font-size: 14px;
                    line-height: 21px;
                    @media (max-width: 600px) {
                        display: none;
                    }
                }
                .photo {
                    width: 36px;
                    height: 36px;
                    img {
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                    }
                }

            }
        }
    }
}